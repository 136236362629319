import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import PresetEditor from './pages/presetEditor';
import PresetManager from './pages/presetManager';


const App = () => {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<PresetManager />} />
        <Route path="/PresetEditor" element={<PresetEditor></PresetEditor>}/>
        <Route path="/presetCompare" element={<PresetManager />}/>
      </Routes>
    </Router>
  );
}

export default App;
