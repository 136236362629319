import React, { useState } from 'react';
import "../components_css/dragdrop.css";


const DragAndDropParent = ({ children ,handleDrop, style} ) => {
  
  const [dragging, setDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const parentHandleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    handleDrop(e);
  }


  return (
    <div
      className={`drop-zone ${dragging ? 'dragging' : ''}`}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={parentHandleDrop}
      /*style={{
        position: 'relative', // Establece el posicionamiento relativo
        width: '100vw',
        height: '100vh',
        zIndex: 1, // Asegúrate de que esté detrás de otros elementos
      }}*/
    >
      <h2 className='dropit-text'>Drop it! </h2>
      {children}
    </div>
  );
};

export default DragAndDropParent;

