import React from 'react'
import '../components_css/allinone.css'
import TartaNav from '../modules/tartanav';

import a3preset from '../lib/armapresets';
import addlogo from '../logos/logoadd.svg';
import { useState } from 'react';
import DragAndDropParent from '../modules/dragDrop';
import PresetSmallCard from '../modules/presetSmallCard';

function InfoPanel(params) {
    let btext =  "Mods you need to dowload if you have the reference preset"  
    if (params.mode === "merge") {
      btext =  "Total weight of the new preset"
    }
    
    return(
        <div className='infopanel'>
            <div className='allModsDownload'>
            <p> Total download </p>
            <h3>{params.totalWeight} Gb</h3>

            </div>

            <div className='referenceDownload'>
            
            <p>{btext}</p>
            <h3>{params.dowloadWeight} Gb</h3>

            <button style={{display : params.mode === "merge"? ('block'): ('none')}}>
              MERGE selected
            </button>

            </div>
        </div>
    )
}

export default function PresetManager() {


  const presetLoaded = JSON.parse(sessionStorage.getItem("presetLoaded")) || new a3preset();
  const [presetsLoaded , setPresetsLoaded] = useState(presetLoaded.presetName === '' ? ([]) : ([presetLoaded]) );
  const [totalWeight , setTotalWeight] = useState(presetLoaded.presetName === '' ? (0.00) : ( presetLoaded.weight) );
  const [dowloadWeight , setdowloadWeight] = useState(0.00);
  const [appMode, setappmode] = useState("compare"); 
  const [presets2merge , setPreset2merge ] = useState({})

  const calculateTotalWeight = (presetsToWeight) => {
    const modcache = JSON.parse(sessionStorage.getItem("mods_cache"));
    let mods = [];
    let totalWeight = 0;
    for (let preset of presetsToWeight) {
      for (let mod of Object.keys(preset.mods)) {
        if (!mods.includes(mod)) {
        mods.push(mod) 
        totalWeight = totalWeight +  modcache[mod].weight
        }
      }
    }   
    setTotalWeight((totalWeight/(1024*1024*1024)).toFixed(2))
  }

  const handleLoad = async (e) => {
    let a3p = new a3preset();
    await a3p.loadEvent(e);
    a3p.presetName = a3p.presetName.replace(".html", " (")  + Object.keys(a3p.mods).length + " mods)"
    
    const elements = document.getElementsByClassName("presetname");
    const namesArray = [...elements].map(x => x.innerText);
    if (namesArray.includes(a3p.presetName)) {
      return;
    }

    if (sessionStorage.getItem("presetLoaded") === null  ) {
      sessionStorage.setItem("presetLoaded", JSON.stringify(a3p));
    }

    sessionStorage.setItem("allPresetLoaded", JSON.stringify(presetsLoaded.concat(a3p)));
    setPresetsLoaded( presetsLoaded.concat(a3p) );    
    comparePresets(presetsLoaded.concat(a3p) );
    calculateTotalWeight( presetsLoaded.concat(a3p))
    
  }
  
  const comparePresets = (presetsLoaded) => {

    const presetselected = JSON.parse(sessionStorage.getItem("presetLoaded"));
    if (presetselected.presetName === '' ) {return; };
    const presetCards = document.getElementsByClassName("presetCardSmall"); 
    
    /*for (let card of presetCards) {
      const presetname = card.getElementsByClassName('presetname')[0].textContent;
      if (presetname.includes(presetselected.presetName) ) {
        //card.style.boxShadow = "0px 0px 10px 1px #E2A750";
        card.style.border = "solid 2px #E2A750";
      } else {
        //card.style.boxShadow = "None";
        card.style.border = "solid 1px rgba(245, 245, 245, 0.2)";
      }
    }*/

       
    const modsExcluded = Object.keys(presetselected.mods || {});
    let downloadWeight = 0;
    const alreadycounted = [];
    const modcache = JSON.parse(sessionStorage.getItem('mods_cache')) || {};
      
    if (presetsLoaded.length < 2 ) {return; };

    for (let p of presetsLoaded ) {
      if (p.presetName === presetselected.presetName) {continue};

      for (let modid of Object.keys(p.mods) ) {
        if ( !alreadycounted.includes(modid) && !modsExcluded.includes(modid ) ) {
          alreadycounted.push(modid)
          downloadWeight = downloadWeight + Number(modcache[modid].weight )
        }
      }
    }
    setdowloadWeight((downloadWeight/(1024*1024*1024)).toFixed(2))
    //update tables
    for (let card of presetCards) { 
      //if (card.getElementsByClassName("presetname")[0].text === presetselected.presetName) {continue};
      const modlist = card.getElementsByTagName("tbody")[0].children
      
      for ( let modtr of modlist) {
        if ( modsExcluded.includes(modtr.id)) {
          modtr.style.backgroundColor = "rgba(0, 100, 0, 0.4)";

        } else{
          modtr.style.backgroundColor = "rgba(139, 0, 0, 0.4)";
          
        }
      }

    }
  };

  const handleDelete = (presetNameToRemove) => {
    //if (presetNameToRemove === JSON.parse(sessionStorage.getItem("presetLoaded")).presetName ) {return};
    let AllpresetsLoaded = JSON.parse(sessionStorage.getItem("allPresetLoaded"));
    const currentPresetAsReference = JSON.parse(sessionStorage.getItem("presetLoaded")); 
    let newpresetload = AllpresetsLoaded.filter(preset => preset.presetName !== presetNameToRemove);
    
    
    if (presetNameToRemove === currentPresetAsReference ) { //lo saca si es el mismo que esta de referencia
      sessionStorage.setItem("presetLoaded", JSON.stringify(new a3preset()))
    };
    
    if (newpresetload.length === 0) { //si no queda preset cargado vacia todo 
      sessionStorage.setItem("presetLoaded", JSON.stringify(new a3preset())); 
      newpresetload = []; 
    }; 

    sessionStorage.setItem("allPresetLoaded", JSON.stringify(newpresetload))

    setPresetsLoaded(newpresetload)
    calculateTotalWeight(newpresetload)
    comparePresets(newpresetload)    
  }

  function setSelected2Merge(p) {
  
    //const modsSelected2merge = JSON.parse(sessionStorage.getItem("presets2Merge") || "{}");
    console.log(p)
    if ( Object.keys(presets2merge).includes(p.preset.presetName)) { 
      const newPreset2Merge = presets2merge[p.preset.presetName] = p.preset;
      delete newPreset2Merge[p.preset.presetName]; 
      setPreset2merge(newPreset2Merge )      
      return;
    }
    //sessionStorage.setItem("presets2Merge", JSON.stringify(modsSelected2merge)) ;
    const newPreset2Merge = presets2merge[p.preset.presetName] = p.preset;
    setPreset2merge(newPreset2Merge )
  }


  const presetCards = presetsLoaded.map((preset, index) => (
    <PresetSmallCard
      key={index}
      preset={preset}
      calculateTotalWeight={calculateTotalWeight}
      comparePresets = {comparePresets}
      handleDelete = {handleDelete}
      presetsLoaded = {presetsLoaded}
      setSelected2Merge = {setSelected2Merge}
      presets2merge = {presets2merge}
      mode = {appMode}
    /> 
  ));

  const handleDrop = async (e) => {
    const files = [...e.dataTransfer.files];
    // Do something with the dropped files, like upload or process them
    let a3p = new a3preset();
    await a3p.readFile(files[0]);
    a3p.presetName = a3p.presetName.replace(".html", " (")  + Object.keys(a3p.mods).length + " mods)"
    
    const elements = document.getElementsByClassName("presetname");
    const namesArray = [...elements].map(x => x.innerText);
    
    if (namesArray.includes(a3p.presetName)) {
      
      return;
    }

    if (sessionStorage.getItem("presetLoaded") === null  ) {
      sessionStorage.setItem("presetLoaded", JSON.stringify(a3p));
    }
    sessionStorage.setItem("allPresetLoaded", JSON.stringify(presetsLoaded.concat(a3p)));

    setPresetsLoaded( presetsLoaded.concat(a3p) );    
    comparePresets(presetsLoaded.concat(a3p) );
    calculateTotalWeight( presetsLoaded.concat(a3p))
  };
  /*
  
    <img type="image/svg+xml" src={steamimport}     alt="" onClick={null} ></img>
    <img className={ appMode === 'compare'? ('None'):('toggled') } type="image/svg+xml" src={compare} alt="" onClick={() => setappmode('merge')} ></img>
    <img className={ appMode === 'merge'? ('None'):('toggled') } type="image/svg+xml" src={merge} alt="" onClick={() => setappmode('compare')} ></img>
  */
 /*
 <div className='header-buttons'> 
            <div className='swtich-wrapper'>
              <p className={ appMode === 'merge'? ('None'):('selected') }  onClick={() => setappmode('compare')}>Compare</p>
              <p className={ appMode === 'compare'? ('None'):('selected')   }  onClick={() => setappmode('merge')}>Merge</p>
            </div>
          </div>*/
  return (
    
    
    <div className='compareAPP'>
        <TartaNav></TartaNav>
        <h1> Compare presets </h1>
        <DragAndDropParent handleDrop={handleDrop}>

        <InfoPanel 
            totalWeight = {totalWeight}
            dowloadWeight = {dowloadWeight}
            mode = {appMode}
        />

        <div className='presets-header'>
          
          <p className='header-text'> Compare presets 2.0 BETA</p>

        </div>
        <div className='presets'>
            {presetCards}
            <div className='presetAdd'>
            <input type="file" id="presetUpload" onChange={handleLoad} style={{display:'none'}}></input>
            <label htmlFor="presetUpload" className='loadButton'>
                <object  type="image/svg+xml" data={addlogo} aria-label="Logo"></object>
            </label>
            </div>
        </div>
        </DragAndDropParent>
    </div>
  );
}

