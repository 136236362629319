//import { type } from "@testing-library/user-event/dist/type";

class a3preset {
    constructor(presetName = "", mods = {}) {
        this.presetName = presetName; 
        this.mods = mods; 
        this.modids = Object.keys(mods);
        this.weight = 0;
        this.preset =  this.buildPreset();;
    }

    getModIds(preset) {
        let mods = {};

        const modlist = preset.querySelector('.mod-list');
        const elementosTR = modlist.querySelectorAll('tr[data-type="ModContainer"]');
        
        elementosTR.forEach( trs => {
            let content = trs.getElementsByTagName("td");
            let modName = content[0].innerText;
            let source = content[1].getElementsByTagName("span")[0].innerText;
            
            if (source === "Steam") {
                let modid =content[2].getElementsByTagName("a")[0].href.split("=")[1];             
                mods[modid] = modName; 
            };
        })

        return(mods);
    }

    readModsFromPresetFile (fileContent) {
        const iframe = document.createElement("iframe");
        iframe.style.display = "None";
        iframe.style.height = "0px";
        iframe.style.width = "0px";
        document.body.appendChild(iframe);
        iframe.contentWindow.document.body.innerHTML = fileContent;
        const modids = this.getModIds(iframe.contentWindow.document);
        const parent = iframe.parentNode;
        parent.removeChild(iframe);
        this.mods = modids
        return this
        //return(modids)    
    }

    loadEvent(event) {
        return new Promise((resolve, reject) => {
            const file = event.target.files[0];
            const reader = new FileReader();
            const fileName = file.name;

            reader.onload = async (e) => {
                const fileContent = e.target.result;
                //const preset = new a3preset();
                this.readModsFromPresetFile(fileContent);
                this.presetName = fileName;
                await this.weightMods()
                // Resuelve la promesa con la instancia de a3preset
                resolve(this);
            };

            // Maneja errores de lectura del archivo
            reader.onerror = function(err) {
                reject(err);
            };

            // Lee el archivo como texto
            reader.readAsText(file);
        });
        
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            const fileName = file.name;

            reader.onload = async (e) => {
                const fileContent = e.target.result;
                //const preset = new a3preset();
                this.readModsFromPresetFile(fileContent);
                this.presetName = fileName;
                await this.weightMods()
                // Resuelve la promesa con la instancia de a3preset
                resolve(this);
            };

            // Maneja errores de lectura del archivo
            reader.onerror = function(err) {
                reject(err);
            };

            // Lee el archivo como texto
            reader.readAsText(file);
        });
    }
    
    async weightMods() {
        this.weight = 0;
        
        const modids = Object.keys(this.mods);
        
        let modid_cache = sessionStorage.getItem('mods_cache') || {};
        const modids_en_cache = Object.keys(modid_cache);
        let modids_request = []; 
        modids.forEach( mod => {
            if (!modids_en_cache.includes(mod)) {
                modids_request.push(mod);
            }
        })        
        const askApi = (modids_request) => {

            if (modids_request.length === 0) {return;}

            return new Promise(async (resolve, reject) => {
                try {
                    const url = `https://tools.flyingtarta.com/api/modsweight?mods=[${modids_request}]`;
                    const response = await fetch(url);
                    
                    if (response.ok) {
                        const data = await response.json();
                        let modid_cache = JSON.parse(sessionStorage.getItem('mods_cache')) || {};
                        const updated = Object.assign({}, modid_cache, data);
                        sessionStorage.setItem('mods_cache', JSON.stringify(updated));
                        resolve(true);
                    } else {
                        throw new Error("Network response was not ok.");
                    }
                } catch (error) {
                    reject(error);
                    
                }
            });
        };
        
        
        await askApi(modids_request)
        modid_cache = JSON.parse(sessionStorage.getItem('mods_cache'))
        modids.forEach( (modid) => {
            try{
                if ( modid_cache[Number(modid)] || false  ) {
                    
                    (this.weight = this.weight + (modid_cache[Number(modid)].weight)/(1024*1024*1024));

                };

            }catch (error){
                /*modid_cache[modid] = 0;
                modid_cache = sessionStorage.setItem('mods_cache', JSON.stringify(modid_cache))*/
                console.log(error, modid )
            }
        })
        this.weight = this.weight.toFixed(2)
    }

    EmptyPresetInit(presetName) {


        function removeBOMFromString(str) {
            if (str.charCodeAt(0) === 0xFEFF) {
                return str.slice(1);
            }
            return str;
        }
        const emptyPreset = `
        <html>
        <!--Created by Arma 3 Launcher: https://arma3.com-->
        <head>
        <meta name="arma:Type" content="preset" />
        <meta name="arma:PresetName" content="${presetName}" />
        <meta name="generator" content="Arma 3 Launcher - https://arma3.com" />
        <title>${presetName}</title>
        <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" type="text/css" />
        <style>
            body {
                margin: 0;
                padding: 0;
                color: #fff;
                background: #000;	
            }

            body, th, td {
                font: 95%/1.3 Roboto, Segoe UI, Tahoma, Arial, Helvetica, sans-serif;
            }

            td {
                padding: 3px 30px 3px 0;
            }

            h1 {
                padding: 20px 20px 0 20px;
                color: white;
                font-weight: 200;
                font-family: segoe ui;
                font-size: 3em;
                margin: 0;
            }

            em {
                font-variant: italic;
                color:silver;
            }

            .before-list {
                padding: 5px 20px 10px 20px;
            }

            .mod-list {
                background: #222222;
                padding: 20px;
            }

            .dlc-list {
                background: #222222;
                padding: 20px;
            }

            .footer {
                padding: 20px;
                color:gray;
            }

            .whups {
                color:gray;
            }

            a {
                color: #D18F21;
                text-decoration: underline;
            }

            a:hover {
                color:#F1AF41;
                text-decoration: none;
            }

            .from-steam {
                color: #449EBD;
            }
            .from-local {
                color: gray;
            }

            </style>
            </head>
            <body>
                <span>Edited with Tarta Tools (tools.flyingtarta.com).</span>
                <h1>Arma 3  - Preset <strong>${presetName}</strong></h1>
                <p class="before-list">
                <em>To import this preset, drag this file onto the Launcher window. Or click the MODS tab, then PRESET in the top right, then IMPORT at the bottom, and finally select this file.</em>
                </p>
                <div class="mod-list">
                <table>
                </table>
                </div>
                <div class="dlc-list">
                <table />
                </div>
                <div class="footer">
                <span>Created by Arma 3 Launcher by Bohemia Interactive.</span>
                <span>Edited with Tarta Tools (tools.flyingtarta.com).</span>
                </div>
            </body>
            </html>
        `;

        const emptyPresetClean = removeBOMFromString(emptyPreset);

        const parser = new DOMParser();
        const doc = parser.parseFromString(emptyPresetClean, "text/xml");
        return doc; 
    };

    AddMod2Preset(preset = this.preset , modid, modname) {
        const modContainer = preset.createElement("tr")
        modContainer.setAttribute('data-type', 'ModContainer');
        modContainer.innerHTML = `
            <td data-type="DisplayName">${modname}</td>
            <td>
                <span class="from-steam">Steam</span>
            </td>
            <td>
                <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=${modid}" data-type="Link">https://steamcommunity.com/sharedfiles/filedetails/?id=${modid}</a>
            </td>
        `;
        const table = preset.querySelector("body > div.mod-list > table");
        table.appendChild(modContainer);
        return preset;
    }

    buildPreset(modids = this.mods) {
        
        let preset = this.EmptyPresetInit(this.presetName);            
        const idlist = Object.keys(modids);

        if (!(idlist.length >0)) {
            return(preset);
        };

        idlist.forEach(key => {
            preset = this.AddMod2Preset(preset,key, modids[key] );
        });
        this.preset = preset;
        return(preset)
        //}; 
        //console.log('no mod ids', modids.length, modids); 
    };

    DownloadPreset() {
        const filename = this.presetName + ".html";
        // Crear un enlace temporal
        const link = document.createElement("a");
        link.href = "data:text/html;charset=utf-8," + encodeURIComponent(this.preset.documentElement.outerHTML);
        link.download = filename;
        // Simular un clic en el enlace para iniciar la descarga
        link.click();
    }


}

export default a3preset;
