import React from 'react'
import { useState } from 'react';
import "../components_css/preset_small_card.css";
import trashcan from '../logos/tashcan.svg';
import edit from '../logos/write-f.svg';

import show from '../logos/list.svg';
import hide from '../logos/hide.svg';
import logoadd from '../logos/logoadd.svg';
import a3preset from '../lib/armapresets';
import { useNavigate } from 'react-router-dom';
export default function  PresetSmallCard(p) {


    const allpresets =  JSON.parse(sessionStorage.getItem("allPresetLoaded")) || [];    
    const [showmods , setshowmods] = useState(allpresets.length < 2);
    function handleSelectedAsRef() {
      
      const isPresetLoaded = JSON.parse( sessionStorage.getItem("presetLoaded")).presetName === p.preset.presetName ; 
      if (isPresetLoaded) {
        
        sessionStorage.setItem("presetLoaded", JSON.stringify(new a3preset()))
      } else {
        
        sessionStorage.setItem("presetLoaded", JSON.stringify(p.preset))
      }
      p.comparePresets(p.presetsLoaded)      
    }
    
    const Tableofmods = (armapreset) => {
      let table = [];
      const modcache = JSON.parse(sessionStorage.getItem('mods_cache')) || {};
      for (let mod in armapreset.mods) {
        let modweight = modcache[mod]?.weight;
        modweight= (modweight/(1024*1024*1024)).toFixed(2)
        table.push(
          <tr key={mod} id={mod}>
            <td>{modcache[mod]?.modname}</td>
            <td className='modweight'>{modweight + " Gb"}</td>        
          </tr>
        );
      }
      return table;
    };   
     
    //<h3 onClick={handleSelectedAsRef} className='presetname'              >{p.preset.presetName} </h3> 
    const navigate = useNavigate();
    const isPresetLoaded = JSON.parse( sessionStorage.getItem("presetLoaded")).presetName === p.preset.presetName ; 
    //const selected2Merge = JSON.parse(sessionStorage.getItem("presets2Merge")) || {};
    const isSelected2Merge = Object.keys(p.presets2merge).includes(p.preset.presetName);
    const goToEdit= (p) => {
      sessionStorage.setItem("presetLoaded", JSON.stringify(p));
      
      navigate('/PresetEditor');
    };

    return(
      <div className='presetCardSmall'
        style= { (p.mode === "compare" &&  isPresetLoaded) || (p.mode=== "merge" && isSelected2Merge) ? ({
          border: "solid 2px #E2A750"
        }):({})}
      >
          <div>
            <div className='reference' style={{display : p.mode === "compare"? ("flex") : ("none")}}>
              <div className='checkbox' 
                onClick= {handleSelectedAsRef}
                style = { 
                  isPresetLoaded? (
                  {
                    backgroundColor : "#E2A750", 
                    color: "black"
                  }):({})
                }

              >Ref</div>
              
            </div>

            <div className='merge' style={{display : p.mode === "merge"? ("flex") : ("none")}}>
              
                <img  type="image/svg+xml" src={logoadd}     alt=""
                  className='merge-checkbox'
                  onClick= {() => p.setSelected2Merge(p)}

                  style={
                    isSelected2Merge? ({
                      filter: "none"
                    }):({
                      filter: "grayscale()"
                      /*backgroundColor : "#202020", 
                      color: "white"*/
                    })
                  }               
                ></img>
              
            </div>

            <h3 className='presetname'> {p.preset.presetName} </h3> 
          
          <div className='mods-button'>
            <h5  >{p.preset.weight}Gb</h5>    
            <img  type="image/svg+xml" alt="" src={showmods? (hide) : (show)}     onClick={() => { setshowmods(!showmods)} }  ></img>
          </div>
          <div className='preset-buttons'> 
          
          <img  type="image/svg+xml" src={edit}     alt="" onClick={() => goToEdit(p.preset)} ></img>
          <img  type="image/svg+xml" src={trashcan} alt="" onClick={() => {p.handleDelete(p.preset.presetName)}} ></img>

          </div>
        </div>
  
        <table className='modtable' style={{ "display": showmods? ("table") : ("None") }}>
          <tbody>
            {Tableofmods(p.preset)}
          </tbody>
        </table>
      </div>
    )
  
};