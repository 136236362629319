import '../App.css';
//import '../components_css/allinone.css';
import '../components_css/presetEditor.css';
import a3preset from '../lib/armapresets';
import addlogo from '../logos/logoadd.svg';
import { useState } from 'react';
import DragAndDropParent from '../modules/dragDrop';
import { interpolateTurbo } from 'd3-scale-chromatic';

//import PresetSmallCard from '../modules/presetSmallCard';
import trashcan from '../logos/tashcan.svg';
import edit from '../logos/write-f.svg';



function A3mod({modid}) {
  
  const mod = JSON.parse(sessionStorage.getItem("mods_cache"))[modid]; 
  
  const colorInterpolator = interpolateTurbo;
  
  const modweight = ( ( mod.weight || 0) / (1024*1024*1024)).toFixed(2); 
  let mw = (modweight/7)+ 0.1;
  if (mw > .8) {mw = .9};
  
  

  return (
    <div className='a3mod'>
      <a className='modName' href={'https://steamcommunity.com/sharedfiles/filedetails/?id=' + modid}> {mod.modname} </a>
      <p className='weight' style={{"color": colorInterpolator(mw)   }}> {modweight || "00.0"} Gb</p>
      <img type="image/svg+xml" src={trashcan} alt="delete" onClick={() => console.log("delete")}></img>
    </div>
  )
}



function PresetE({preset}) {

  if (!preset ) {return (<></>)};

  console.log("PRESETE", preset); 
  const modsInPreset = Object.keys(preset.mods);

  if (modsInPreset.length === 0) { return ( <div className='presetEditor'></div>)}; 
  
  const mods = modsInPreset.map( (modid, index)  => ( 
      
      <A3mod modid={modid} key={modid} />
    ));

  return(
    <div className='presetEditor'>
      <div className='presetnameInput'>
        <input type="text" placeholder={preset.presetName}></input>
      </div>
      {mods}

    </div>
  )
};


function SteamItem(){
  <div className='steamItem'>

  </div>
}

function SteamSearch({modsInPreset, setModsInPreset}) {
  return(
    <div className='SteamSearch'>
      <div className='searchBox'>
        <input className='inputTextSteamSearch' type="text" id="steamSearch" name="SteamSeach" placeholder='Search in steamworkshop' minLength="4" size="10"/>
        <img type="image/svg+xml" src={edit} alt=""></img>
      </div>

      <div className='Mods'>

      </div>

    </div>
  )
};



function PresetEditor1() {
  

    const [armapreset, setarmapreset] = useState( JSON.parse(sessionStorage.getItem("presetLoaded")) || new a3preset("<no preset loaded>") );

    const handleLoad = async (e) => {        
      let a3p = new a3preset();
      a3p.presetName = a3p.presetName.replace(".html", " (")  + Object.keys(a3p.mods).length + " mods)"
      await a3p.loadEvent(e);
      sessionStorage.setItem("presetLoaded", JSON.stringify(a3p));
      setarmapreset(a3p);
    }
    
    /*const handleDrop = async (e) => {
      const files = [...e.dataTransfer.files];
      let a3p = new a3preset();
      await a3p.readFile(files[0]);
      a3p.presetName = a3p.presetName.replace(".html", " (")  + Object.keys(a3p.mods).length + " mods)"
      sessionStorage.setItem("presetLoaded", JSON.stringify(a3p));
      setarmapreset(a3p)
    };*/
  

    return (
      <>
        <h3>presetEditor</h3>
        <input type="file" id="fileInput-main" onChange={handleLoad}></input>
        <label htmlFor="fileInput-main" className='loadButton'>UPLOAD</label>
        <h1> {armapreset.presetName} | mods: {Object.keys(armapreset.mods).length}</h1>
        <div className="PresetEditor">
          
            <div style= {{
              display : "flex",
              flexDirection : "row"
            }}>
            <PresetE
              preset = {armapreset}
            />
            <SteamSearch
              preset = {armapreset}
              /*modsInPreset={modsInPreset}
              setModsInPreset={setModsInPreset}*/
            />
          
          </div>
        </div>
      </>
    );
  }


function PresetEditor() {
  

    const [armapreset, setarmapreset] = useState( JSON.parse(sessionStorage.getItem("presetLoaded")) || new a3preset("<no preset loaded>") );
    const [addModInputMode, setAddModInputMode] = useState("link");
    const handleLoad = async (e) => {        
      let a3p = new a3preset();
      a3p.presetName = a3p.presetName.replace(".html", " (")  + Object.keys(a3p.mods).length + " mods)"
      await a3p.loadEvent(e);
      sessionStorage.setItem("presetLoaded", JSON.stringify(a3p));
      setarmapreset(a3p);
    }
    
    /*const handleDrop = async (e) => {
      const files = [...e.dataTransfer.files];
      let a3p = new a3preset();
      await a3p.readFile(files[0]);
      a3p.presetName = a3p.presetName.replace(".html", " (")  + Object.keys(a3p.mods).length + " mods)"
      sessionStorage.setItem("presetLoaded", JSON.stringify(a3p));
      setarmapreset(a3p)
    };*/
  
    //<h1> {armapreset.presetName} | mods: {Object.keys(armapreset.mods).length}</h1>

    return (
      <h1> SOON </h1>
    );

    return (
      <>
        <div style={{"display": "None"}}>
          <h3>presetEditor</h3>
          <input type="file" id="fileInput-main" onChange={handleLoad}></input>
          <label htmlFor="fileInput-main" className='loadButton'>UPLOAD</label>
        </div>
        

        <div className="PresetEditor">
          <div className='presetNameWrap'>
            <input type='text' className='PresetName' placeholder='PRESET NAME HERE '></input>
            <img type="image/svg+xml" src={edit} alt=""></img>
          </div>
          <div className='addmod'>
            <h2> Add mod</h2>
            <div className='addmod-modeSelector'>
              <h3 className='steamSearch' onClick={() => setAddModInputMode("search")}> Search </h3>
              <h3 className='steamLink' onClick={() => setAddModInputMode("link")} > Link </h3>
              <h3 className='steamColection' onClick={() => setAddModInputMode("colection")} > Colection </h3>
            </div>
            <div className='addInput'>
              <input type='text' className='addmod-text'></input>
              {addModInputMode === "search"? (<SteamSearch/>):(null)}

            </div>


            <p>or Drag and Drop a preset to merge it</p>
          </div>

          <PresetE />          
        </div>
      </>
    );
  }
  

  export default PresetEditor;